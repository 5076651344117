<template lang="pug">
.messages
  .messages__wrapper
    .messages__sidebar
      .messages__sidebar-user
        .messages__sidebar-user-info
          LAvatar
          .messages__sidebar-user-name
            span FirstName
            span LAstName

          LIcon(name="message" color="#FFF")

        .messages__sidebar-search
          LSearch

      .messages__sidebar-user-list
        LMessageUser
        LMessageUser
        LMessageUser
        LMessageUser
        LMessageUser
        LMessageUser
        LMessageUser
        LMessageUser
        LMessageUser
        LMessageUser
        LMessageUser
        LMessageUser
        LMessageUser
        LMessageUser
        LMessageUser
        LMessageUser
        LMessageUser

    .messages__content
      LMessagesView

</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Messages',
  components: {
    LAvatar: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LAvatar'),
    LIcon: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LIcon'),
    LSearch: () => import(/* webpackChunkName: "global" */ '@/components/global/LSearch'),
    LMessageUser: () => import(/* webpackChunkName: "composite" */ '@/components/composite/LMessageUser'),
    LMessagesView: () => import(/* webpackChunkName: "composite" */ '@/components/composite/LMessagesView'),
  },
  created () {
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('user', ['SETTINGS']),
  },
  methods: {
  },
}
</script>

<style lang="scss">
.messages {
  width: 100%;
  background: #fff;
  min-height: calc(100vh - 100px);
  &__wrapper {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    min-height: calc(100vh - 100px);
    max-height: calc(100vh - 100px);
  }
  &__sidebar {
    width: 300px;
    flex-shrink: 0;
    min-height: calc(100vh - 100px);
    max-height: calc(100vh - 100px);
    &-user {
      background: #C4C4C4;
      height: 145px;
      padding: 15px 15px 20px 15px;
      border-radius: 0px 5px 0px 0px;
      &-name {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        & > span {
          color: #000;
          font-weight: 800;
          margin: 3px 0;
        }
      }
      &-info {
        display: flex;
        align-items: center;
        padding-bottom: 15px;
        & > svg {
          width: 36px;
          height: auto;
          margin-left: auto;
        }
      }
      &-list {
        padding-top: 16px;
        padding-bottom: 16px;
        max-height: calc(100vh - 245px);
        min-height: calc(100vh - 245px);
        overflow-y: auto;
        box-sizing: border-box;
      }
    }
  }
  &__content {
    width: 100%;
    min-height: calc(100vh - 100px);
    max-height: calc(100vh - 100px);
  }
}
</style>